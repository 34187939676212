import React from "react";
import i18next from "i18next";
import { NavLink } from "react-router-dom";
import DefaultLayout from "./../layouts/Default";
import { clearFilters } from "../store/filter/actions";
import VideoPlaybackSettingsForm from "../components/common/VideoPlaybackSettingsForm/VideoPlaybackSettingsForm";


export default function DisclaimerPage() {
    return (
        <DefaultLayout
            content={
                <div className="page__content container">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1">
                            <NavLink
                                className="category__backlink home-backlink active"
                                to={`/`}
                                onClick={() => clearFilters()}
                            >
                                {i18next.t("Home")}
                            </NavLink>
                            <h1 className="mb-4">Datenschutzerklärung</h1>
                            <h5>
                                § 1 Information über die Erhebung personenbezogener Daten
                            </h5>
                            <p>
                                (1) Im Folgenden informieren wir über die
                                Erhebung personenbezogener Daten bei Nutzung
                                unserer Website. Personenbezogene Daten sind
                                alle Daten, die auf Sie persönlich beziehbar
                                sind, z. B. Name, Adresse, E-Mail-Adressen,
                                Nutzerverhalten.
                            </p>
                            <p>
                                (2) Verantwortlicher gem. Art. 4 Abs. 7
                                EU-Datenschutz-Grundverordnung (DSGVO) für die
                                Verarbeitung von personenbezogenen Daten auf
                                dieser Website ist
                            </p>
                            <p>
                                <strong>
                                    Berufsgenossenschaft für Gesundheitsdienst
                                    und Wohlfahrtspflege (BGW)
                                </strong>
                                <br />
                                Pappelallee 33/35/37
                                <br />
                                22089 Hamburg
                            </p>
                            <p>
                                Der Datenschutzbeauftragte ist unter der o.g.
                                Anschrift beziehungsweise unter
                                datenschutz@bgw-online.de erreichbar.
                            </p>
                            <p>
                                <strong>
                                    Unfallkasse Nordrhein-Westfalen (UK NRW)
                                </strong>
                                <br />
                                Moskauer Straße 18
                                <br />
                                40227 Düsseldorf
                            </p>
                            <p>
                                Der Datenschutzbeauftragte ist unter der o.g.
                                Anschrift beziehungsweise unter
                                datenschutz@unfallkasse-nrw.de erreichbar.
                            </p>
                            <p>
                                (3) Bei Ihrer Kontaktaufnahme mit uns per E-Mail
                                oder unserem Kontaktformular werden die von
                                Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse,
                                ggf. Ihr Name und Ihre Telefonnummer) von uns
                                gespeichert, um Ihre Fragen zu beantworten. Die
                                Speicherung erfolgt gem. Art. 6 Abs. 1 Ziff b
                                DSGVO und dient der vertraglichen oder
                                vorvertraglichen Kontaktaufnahme. Die in diesem
                                Zusammenhang anfallenden Daten löschen wir
                                innerhalb von drei Monaten, insoweit kein
                                Vertragsverhältnis zustande kommen sollte. Nach
                                Zustandekommen eines Vertragsverhältnisses
                                werden Daten nach Beendigung des
                                Vertragsverhältnisses gelöscht, insoweit der
                                Löschung keine gesetzlichen
                                Aufbewahrungspflichten z.B. § 147 AO
                                entgegenstehen.
                            </p>
                            <p>
                                <br />
                            </p>
                            <h5>§ 2 Ihre Rechte</h5>
                            <p>
                                (1) Sie haben gegenüber uns folgende Rechte
                                hinsichtlich der Sie betreffenden
                                personenbezogenen Daten:
                                <br />– Recht auf Auskunft Art. 15 DSGVO
                                <br />– Recht auf Berichtigung oder Löschung
                                Art. 16 DSGVO Art. 17 DSGVO
                                <br />– Recht auf Einschränkung der Verarbeitung
                                Art. 18 DSGVO
                                <br />– Recht auf Widerspruch gegen die
                                Verarbeitung Art. 21 DSGVO
                                <br />– Recht auf Datenübertragbarkeit Art. 20
                                DSGVO
                            </p>
                            <p>
                                (2) Sie haben zudem das Recht, sich bei einer
                                Datenschutz-Aufsichtsbehörde z.B. der
                                Landesbeauftragten für Datenschutz in NRW über
                                die Verarbeitung Ihrer personenbezogenen Daten
                                durch uns zu beschweren.
                            </p>
                            <p>
                                <br />
                            </p>
                            <h5>
                                § 3 Server-Logfiles
                            </h5>
                            <p>
                                (1) Wir erheben durch unserer Server Daten bei Ihrem Besuch unserer Homepage. Diese Daten werden in einem sogenannten
                                Server-Logfile gespeichert und umfassen Ihre IP-Adresse, Datum und Uhrzeit Ihres Zugriffs, die aufgerufene Internetadresse,
                                die Referrer-URL, Ihren Browser und Ihr Betriebssystem.

                                (2) Die Rechtsgrundlage für diese Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO. Unsere berechtigten Interessen beruhen auf
                                den Möglichkeiten, die uns Logfiles zur Wartung und zur Fehlerkorrektur unserer IT-Systeme bieten. Darüber hinaus speichern wir
                                Logfiles für einen kurzen Zeitraum, um eine missbräuchliche Nutzung unserer Homepage ermitteln zu können. Sollte dieser Fall
                                tatsächlich eintreten, speichern wir diese Daten solange, bis sie zu Beweiszwecken nicht mehr erforderlich sind.
                            </p>
                            <p>
                                <br />
                            </p>


                            <h5>§ 4 Cookies</h5>
                            <p>(1) Wir setzen auf dieser Webseite keine eigenen Cookies ein.</p>
                            <p>(2) Drittanbieter-Cookies</p>
                            <p>Bei der Einbindung von Inhalten von Drittanbietern (z. B. Youtube-Videos) kann der Anbieter gegebenenfalls eigene Cookies
                                etzen. Bevor Drittanbieter-Inhalte auf unserer Webseite geladen werden, holen wir Ihre Einwilligung ein. Weitere Informationen
                                zum Datenschutz bei den von uns verwendeten Drittanbietern finden Sie in § 6.</p>
                            <p>(3) Widerspruchs- und Beseitigungsmöglichkeit</p>
                            <p>Sie können durch Änderung der Einstellungen in Ihrem Internetbrowser die Übertragung von Cookies ermöglichen oder beschränken.
                                Cookies, die von Ihrem Internetbrowser bereits gespeichert wurden, können Sie dort jederzeit löschen. Wenn Cookies für unsere
                                Website beschränkt oder deaktiviert werden, kann es sein, dass nicht alle Funktionalitäten genutzt werden können.</p>
                            <p>
                                <br />
                            </p>


                            <h5>§ 5 Einsatz von Matomo (ehemals Piwik)</h5>
                            <p>(1) Diese Website nutzt den Webanalysedienst Matomo (ehemals Piwik), um die Nutzung unserer Website analysieren und regelmäßig
                                verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot verbessern und für Sie als Besuchende interessanter
                                ausgestalten. Rechtsgrundlage für die Nutzung von Matomo ist Art. 6 Abs. 1 lit. f DSGVO (Interessenabwägung). Es liegt in unserem
                                überwiegenden Interesse, unsere Angebote gegenüber unseren Kunden verbessern zu können. Als Betreiber dieser Website haben wir ein
                                berechtigtes Interesse an der anonymisierten Analyse des Nutzerverhaltens, um unser Webangebot zu optimieren.</p>
                            <p>(2) Diese Website verwendet Matomo mit der Erweiterung „AnonymizeIP“. Dadurch werden IP-Adressen gekürzt weiterverarbeitet, eine direkte
                                Personenbeziehbarkeit kann damit ausgeschlossen werden. Die mittels Matomo von Ihrem Browser übermittelte IP-Adresse wird nicht mit
                                anderen von uns erhobenen Daten zusammengeführt.</p>
                            <p>(3) Die Analyse erfolgt ohne Cookies. Wir haben Matomo so konfiguriert, dass Matomo keine Cookies in Ihrem Browser speichert und
                                hosten Matomo ausschließlich auf Servern in Deutschland, so dass alle Analysedaten bei uns verbleiben und nicht weitergegeben werden.</p>
                            <p>(4) Die Verhinderung des Einsatzes von Matomo ist möglich, indem Sie den folgenden Haken entfernen und so das Opt-out-Plug-in aktivieren:</p>
                            <iframe
                                title="matomo"
                                style={{border: "0", height: "200px", width: "600px"}}
                                src="https://rms2logs.de/index.php?module=CoreAdminHome&action=optOut&language=de&backgroundColor=&fontColor=&fontSize=&fontFamily="
                            ></iframe>
                            <p>Das Programm Matomo ist ein Open-Source-Projekt. Informationen des Drittanbieters zum Datenschutz erhalten Sie unter <a href="https://matomo.org/privacy-policy/" target="_blank" rel="noopener noreferrer">https://matomo.org/privacy-policy/</a></p>

                            <p>
                                <br />
                            </p>

                            <h5 id="einbindung-drittanbieter">§ 6 Einbindung von Drittanbieter-Inhalten</h5>

                            <p>Vor der erstmaligen Einbindung von Inhalten, die durch Drittanbieter bereitgestellt werden, in unsere Webseite fragen wir Sie nach Ihrem Einverständnis.
                                Sie können Ihre Einwilligung wahlweise für den einmaligen Aufruf eines externen Inhaltes geben oder die Einbindung externer Inhalte generell erlauben.</p>
                            <p>Falls Sie die Einbindung externer Inhalte generell erlaubt haben, können Sie die Einwilligung dafür an dieser Stelle widerrufen:</p>

                            <VideoPlaybackSettingsForm></VideoPlaybackSettingsForm>
                            <p>
                                <br />
                            </p>

                            <p><strong>YouTube mit erweitertem Datenschutz</strong></p>

                            <p>Diese Website bindet Videos der Website YouTube ein. Betreiber der Website ist die Google Ireland Limited („Google”), Gordon House, Barrow Street, Dublin 4, Irland.</p>
                            <p>Wenn Sie eine dieser Website besuchen, auf denen YouTube eingebunden ist, wird eine Verbindung zu den Servern von YouTube hergestellt. Dabei wird dem YouTube-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Wenn Sie in Ihrem YouTube-Account eingeloggt sind, ermöglichen Sie YouTube, Ihr Surfverhalten direkt Ihrem persönlichen Profil zuzuordnen. Dies können Sie verhindern, indem Sie sich aus Ihrem YouTube-Account ausloggen.</p>
                            <p>Wir nutzen YouTube im erweiterten Datenschutzmodus. Videos, die im erweiterten Datenschutzmodus abgespielt werden, werden nach Aussage von YouTube nicht zur Personalisierung des Surfens auf YouTube eingesetzt. Anzeigen, die im erweiterten Datenschutzmodus ausgespielt werden, sind ebenfalls nicht personalisiert. Im erweiterten Datenschutzmodus werden keine Cookies gesetzt. Stattdessen werden jedoch sogenannte Local Storage Elemente im Browser des Users gespeichert, die ähnlich wie Cookies personenbezogene Daten beinhalten und zur Wiedererkennung eingesetzt werden können. Details zum erweiterten Datenschutzmodus finden Sie hier: <a href="https://support.google.com/youtube/answer/171780" target="_blank" rel="noopener noreferrer">https://support.google.com/youtube/answer/171780</a>.</p>
                            <p>Gegebenenfalls können nach der Aktivierung eines YouTube-Videos weitere Datenverarbeitungsvorgänge ausgelöst werden, auf die wir keinen Einfluss haben.</p>
                            <p>Die Nutzung von YouTube erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar.</p>
                            <p>Weitere Informationen über Datenschutz bei YouTube finden Sie in deren Datenschutzerklärung unter: <a href="https://policies.google.com/privacy?hl=de" target="_blank" rel="noopener noreferrer">https://policies.google.com/privacy?hl=de</a>.</p>
                            <p>Das Unternehmen verfügt über eine Zertifizierung nach dem „EU-US Data Privacy Framework“ (DPF). Der DPF ist ein Übereinkommen zwischen der Europäischen Union und den USA, der die Einhaltung europäischer Datenschutzstandards bei Datenverarbeitungen in den USA gewährleisten soll. Jedes nach dem DPF zertifizierte Unternehmen verpflichtet sich, diese Datenschutzstandards einzuhalten. Weitere Informationen hierzu erhalten Sie vom Anbieter unter folgendem Link: <a href="https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&amp;id=a2zt000000001L5AAI&amp;status=Active" target="_blank" rel="noopener noreferrer">https://www.dataprivacyframework.gov/s/participant-search/participant-detail?contact=true&amp;id=a2zt000000001L5AAI&amp;status=Active</a></p>

                            <p><strong>Vimeo ohne Tracking (Do-Not-Track)</strong></p>

                            <p>Diese Website nutzt Plugins des Videoportals Vimeo. Anbieter ist die Vimeo Inc., 555 West 18th Street, New York, New York 10011, USA.</p>
                            <p>Wenn Sie eine unserer mit Vimeo-Videos ausgestatteten Seiten besuchen, wird eine Verbindung zu den Servern von Vimeo hergestellt. Dabei wird dem Vimeo-Server mitgeteilt, welche unserer Seiten Sie besucht haben. Zudem erlangt Vimeo Ihre IP-Adresse. Wir haben Vimeo jedoch so eingestellt, dass Vimeo Ihre Nutzeraktivitäten nicht nachverfolgen und keine Cookies setzen wird.</p>
                            <p>Die Nutzung von Vimeo erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO dar. Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar.</p>
                            <p>Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission sowie nach Aussage von Vimeo auf „berechtigte Geschäftsinteressen“ gestützt. Details finden Sie hier: <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.</p>
                            <p>Weitere Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Vimeo unter: <a href="https://vimeo.com/privacy" target="_blank" rel="noopener noreferrer">https://vimeo.com/privacy</a>.</p>

                            <p>
                                <br />
                            </p>

                            <h5>§ 7 Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h5>
                            <p>(1) Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit widerrufen. Ein solcher Widerruf wirkt für die zukünftige Verarbeitung nach Ausspruch des Widerrufs.</p>
                            <p>(2) Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Rechtsgrundlage Art. 6 Abs. 1 lit. f DSGVO (Interessenabwägung) stützen, können Sie Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen.</p>


                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            }
        ></DefaultLayout>
    );
}
